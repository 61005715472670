@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

@media (min-width: 960px) {
  .display-m {
    display: none;
  }
}

@media (max-width: 959px) {
  .display-pc {
    display: none;
  }
  .display-m {
    display: flex;
    justify-content: center;
  }
}
